import React from "react"
import { Link } from "gatsby"

// Import Components
import Layout from "../components/layout"
import SEO from "../components/seo"

// Import Effects
import Typewriter from 'typewriter-effect';
import Fade from 'react-reveal/Fade';

const IndexPage = () => (
  <Layout>
    <SEO title="Edward Thring - Image maker, design thinker, code creator." />
    <div className="section">
      <div className="container">
        <div className="row clearfix">
          <div className="column">
            <p className="font-tiempos-headline no-top-bottom">
              <Link to="/" className="text-link">Edward Thring</Link>
            </p>
            <p className="font-tiempos-headline no-top-bottom">
              <Typewriter
                options={{
                  strings: ["G'day mate!", "Projects & full website coming soon...", "...soz, but she'll be apples soon", "Available for freelance work"],
                  autoStart: true,
                  loop: true,
                  delay: (60),
                  deleteSpeed: (50),
                }}
              />
            </p>
          </div>
          <div className="column text-right">
            <ul className="little-links text-right">
              <li>
                <p className="small no-top-bottom">
                  <a href="mailto:hello@edwardthring.com" target="_blank" rel="noopener noreferrer">
                    <span className="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M0 12l11 3.1 7-8.1-8.156 5.672-4.312-1.202 15.362-7.68-3.974 14.57-3.75-3.339-2.17 2.925v-.769l-2-.56v7.383l4.473-6.031 4.527 4.031 6-22z"/></svg>
                    </span>
                    Email Me
                  </a>
                </p>
              </li>
              <li>
                <p className="small no-top-bottom">
                  <a href="https://www.behance.net/edwardthring" target="_blank" rel="noopener noreferrer">
                    <span className="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M22 7h-7v-2h7v2zm1.726 10c-.442 1.297-2.029 3-5.101 3-3.074 0-5.564-1.729-5.564-5.675 0-3.91 2.325-5.92 5.466-5.92 3.082 0 4.964 1.782 5.375 4.426.078.506.109 1.188.095 2.14h-8.027c.13 3.211 3.483 3.312 4.588 2.029h3.168zm-7.686-4h4.965c-.105-1.547-1.136-2.219-2.477-2.219-1.466 0-2.277.768-2.488 2.219zm-9.574 6.988h-6.466v-14.967h6.953c5.476.081 5.58 5.444 2.72 6.906 3.461 1.26 3.577 8.061-3.207 8.061zm-3.466-8.988h3.584c2.508 0 2.906-3-.312-3h-3.272v3zm3.391 3h-3.391v3.016h3.341c3.055 0 2.868-3.016.05-3.016z"/></svg>
                    </span>
                    Behance
                  </a>
                </p>
              </li>
              <li>
                <p className="small no-top-bottom">
                  <a href="https://www.instagram.com/eddiethring" target="_blank" rel="noopener noreferrer">
                    <span className="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                    </span>
                    Instagram
                  </a>
                </p>
              </li>
              <li>
                <p className="small no-top-bottom">
                  <a href="https://dribbble.com/edwardthring" target="_blank" rel="noopener noreferrer">
                    <span className="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M12 0c-6.628 0-12 5.373-12 12s5.372 12 12 12 12-5.373 12-12-5.372-12-12-12zm9.885 11.441c-2.575-.422-4.943-.445-7.103-.073-.244-.563-.497-1.125-.767-1.68 2.31-1 4.165-2.358 5.548-4.082 1.35 1.594 2.197 3.619 2.322 5.835zm-3.842-7.282c-1.205 1.554-2.868 2.783-4.986 3.68-1.016-1.861-2.178-3.676-3.488-5.438.779-.197 1.591-.314 2.431-.314 2.275 0 4.368.779 6.043 2.072zm-10.516-.993c1.331 1.742 2.511 3.538 3.537 5.381-2.43.715-5.331 1.082-8.684 1.105.692-2.835 2.601-5.193 5.147-6.486zm-5.44 8.834l.013-.256c3.849-.005 7.169-.448 9.95-1.322.233.475.456.952.67 1.432-3.38 1.057-6.165 3.222-8.337 6.48-1.432-1.719-2.296-3.927-2.296-6.334zm3.829 7.81c1.969-3.088 4.482-5.098 7.598-6.027.928 2.42 1.609 4.91 2.043 7.46-3.349 1.291-6.953.666-9.641-1.433zm11.586.43c-.438-2.353-1.08-4.653-1.92-6.897 1.876-.265 3.94-.196 6.199.196-.437 2.786-2.028 5.192-4.279 6.701z"/></svg>
                    </span>
                    Dribbble
                  </a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="section more-top no-bottom">
      <div className="container">
        <Fade bottom distance={'0.5em'} duration={1100}>
          <h1 className="no-top">Image maker, design thinker, code creator.</h1>
        </Fade>
      </div>
    </div>
    <div className="section">
      <div className="container">
        <div className="row clearfix">
          <div className="column break-full-760">
            <Fade bottom distance={'0.5em'} duration={600}>
              <div className="padding no-top">
                  <p className="no-top">Edward Thring is an Australian visual artist specializing in design, art direction, and front-end development. Edward has over a decade of experience working for agencies and clients in the US and Australia. He has collaborated with small and big companies solving complex problems while adding value to brands through a modern, intuitive, and visual aesthetic.</p>
                  <p className="no-bottom">His work aspires to create images and visual identities that are playful and relatable while being vigilant on the functional side. In addition to design, Edward continuously evolves his front-end development skills to understand the fundamentals of creating a personalized product from start to finish.</p>
              </div>
            </Fade>
          </div>
          <div className="column break-full-760">
            <Fade bottom distance={'0.5em'} duration={600} delay={300}>
              <div className="padding no-top">
                  <p className="no-top">Great collaboration is the key to establishing a positive impact on a business. Edward understands that design is a tool to help companies achieve their business goals made possible by working closely with open-minded partners.</p>
                  <p className="no-bottom">Edward is not currently looking for full-time opportunities but is always open to collaborations and other fun things. If you’re interested in discussing a project, looking to build something great together, or just getting in touch to have a cold one (digitally that is), <a href="mailto:hello@edwardthring.com" target="_blank" rel="noopener noreferrer" className="underline">shoot through an email</a>.</p>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
    <div className="section no-top">
      <div className="container">
        <p className="smaller opacity-50 no-top-bottom">
          {new Date().getFullYear()} &#169; Edward Thring &mdash; Est. 1988
        </p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
